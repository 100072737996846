addEventListener("direct-upload:initialize", function (event) {
  var target = event.target,
    detail = event.detail;
  var id = detail.id,
    file = detail.file;
  target.insertAdjacentHTML("beforebegin", "\n  <div id=\"direct-upload-".concat(id, "\" class=\"direct-upload direct-upload--pending\">\n    <div id=\"direct-upload-progress-").concat(id, "\" class=\"direct-upload__progress\" style=\"width: 0%\"></div>\n    <span class=\"direct-upload__filename\"></span>\n  </div>\n"));
  target.previousElementSibling.querySelector(".direct-upload__filename").textContent = file.name;
});
addEventListener("direct-upload:start", function (event) {
  var id = event.detail.id;
  var element = document.getElementById("direct-upload-".concat(id));
  if (element) {
    element.classList.remove("direct-upload--pending");
  }
});
addEventListener("direct-upload:progress", function (event) {
  var _event$detail = event.detail,
    id = _event$detail.id,
    progress = _event$detail.progress;
  var progressElement = document.getElementById("direct-upload-progress-".concat(id));
  if (progressElement) {
    progressElement.style.width = "".concat(progress, "%");
  }
});
addEventListener("direct-upload:error", function (event) {
  event.preventDefault();
  var _event$detail2 = event.detail,
    id = _event$detail2.id,
    error = _event$detail2.error;
  var element = document.getElementById("direct-upload-".concat(id));
  if (element) {
    element.classList.add("direct-upload--error");
    element.setAttribute("title", error);
  }
});
addEventListener("direct-upload:end", function (event) {
  var id = event.detail.id;
  var element = document.getElementById("direct-upload-".concat(id));
  if (element) {
    element.classList.add("direct-upload--complete");
  }
});