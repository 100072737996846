// Copied from:
// https://github.com/basecamp/trix/issues/167#issuecomment-208322849
// Converted from Coffeescript to Javascript

var TrixAutoLinker,
  bind = function bind(fn, me) {
    return function () {
      return fn.apply(me, arguments);
    };
  };
addEventListener("trix-initialize", function (event) {
  return new TrixAutoLinker(event.target);
});
TrixAutoLinker = function () {
  var INPUT, PATTERN, isValidURL;
  function TrixAutoLinker(element) {
    this.element = element;
    this.autoLink = bind(this.autoLink, this);
    this.editor = this.element.editor;
    this.element.addEventListener("trix-render", this.autoLink);
    this.autoLink();
  }
  TrixAutoLinker.prototype.autoLink = function () {
    var currentRange, i, len, range, ref, ref1, results1, url;
    ref = this.getURLsWithRanges();
    results1 = [];
    for (i = 0, len = ref.length; i < len; i++) {
      ref1 = ref[i], url = ref1.url, range = ref1.range;
      if (this.getHrefAtRange(range) !== url) {
        currentRange = this.editor.getSelectedRange();
        this.editor.setSelectedRange(range);
        if (this.editor.canActivateAttribute("href")) {
          this.editor.activateAttribute("href", url);
        }
        results1.push(this.editor.setSelectedRange(currentRange));
      } else {
        results1.push(void 0);
      }
    }
    return results1;
  };
  TrixAutoLinker.prototype.getDocument = function () {
    return this.editor.getDocument();
  };
  TrixAutoLinker.prototype.getDocumentString = function () {
    return this.getDocument().toString();
  };
  TrixAutoLinker.prototype.getHrefAtRange = function (range) {
    return this.getDocument().getCommonAttributesAtRange(range).href;
  };
  PATTERN = /(https?:\/\/\S+\.\S+)\s/ig;
  TrixAutoLinker.prototype.getURLsWithRanges = function () {
    var match, position, range, results, string, url;
    results = [];
    string = this.getDocumentString();
    while (match = PATTERN.exec(string)) {
      url = match[1];
      if (isValidURL(url)) {
        position = match.index;
        range = [position, position + url.length];
        results.push({
          url: url,
          range: range
        });
      }
    }
    return results;
  };
  INPUT = document.createElement("input");
  INPUT.type = "url";
  INPUT.required = true;
  isValidURL = function isValidURL(value) {
    INPUT.value = value;
    return INPUT.checkValidity();
  };
  return TrixAutoLinker;
}();