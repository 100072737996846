import '@honeybadger-io/js';
import Honeybadger from '@honeybadger-io/js';
if (document.querySelector('meta[name="honeybadger-api-key"]')) {
  Honeybadger.configure({
    apiKey: document.querySelector('meta[name="honeybadger-api-key"]').content,
    environment: document.querySelector('meta[name="honeybadger-environment"]').content,
    revision: document.querySelector('meta[name="honeybadger-revision"]').content,
    ignorePatterns: [/ResizeObserver loop limit exceeded/i]
  });
} else {
  Honeybadger.configure({
    disabled: true
  });
}