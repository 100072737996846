// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// `delay` milliseconds.
export function debounce(fn) {
  var _this = this;
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
  var timeoutId = null;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    var callback = function callback() {
      return fn.apply(_this, args);
    };
    clearTimeout(timeoutId);
    timeoutId = setTimeout(callback, delay);
  };
}