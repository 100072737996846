import d3 from 'd3';
window.renderTagTree = function (url, documentUrlRoot) {
  var margin = 10,
    outerDiameter = Math.min($(window).width() - 100, 800),
    innerDiameter = outerDiameter - margin - margin;
  var x = d3.scale.linear().range([0, innerDiameter]);
  var y = d3.scale.linear().range([0, innerDiameter]);
  var color = d3.scale.linear().domain([-1, 5]).range(["hsl(228,30%,40%)", "hsl(152,80%,80%)"]).interpolate(d3.interpolateHcl);
  //var color = d3.scale.category20();
  var pack = d3.layout.pack().padding(2).size([innerDiameter, innerDiameter]).value(function (d) {
    return d.size;
  });

  // TODO allow svg to scale with the browser width.
  // But this does not work correctly in IE
  // https://stackoverflow.com/a/13632901
  var svg = d3.select("body .svg .tag-circles").append("svg").attr("width", outerDiameter).attr("height", outerDiameter).append("g").attr("transform", "translate(" + margin + "," + margin + ")");
  svg.append("defs").append("clipPath").attr("id", "clip").append("rect").attr("width", outerDiameter).attr("height", outerDiameter);
  var body = svg.append('g').attr('clip-path', 'url(#clip)');

  // use relative path
  d3.json(url, function (error, root) {
    var focus = root,
      nodes = pack.nodes(root);
    var circles = body.append("g").selectAll("circle").data(nodes).enter();
    circles.append("circle").attr("class", function (d) {
      if (d.hidden) {
        return '';
      } else {
        return d.parent ? "node" : "node node--root";
      }
    }).style("fill-opacity", function (d) {
      return d.hidden == true ? 0 : 1;
    }).attr("cx", function (d) {
      return d.x;
    }).attr("cy", function (d) {
      return d.y;
    }).attr("r", function (d) {
      return d.r;
    }).style("fill", function (d) {
      return color(d.depth);
    }).on("click", function (d) {
      if (d.hidden == true) {
        zoom(d.parent);
      } else if (d.children) {
        zoom(focus == d ? d.parent : d);
      } else {
        zoom(d.parent);
      }
    });
    var empty_image = "data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
    var transform = function transform(d) {
      return "translate(" + d.x + "," + d.y + ")";
    };
    var image_size = 200;
    circles.append("image").filter(function (d) {
      return !d.children;
    }).attr("class", function (d) {
      return d.parent ? "node" : "node node--root";
    }).style("display", function (d) {
      return d.parent === root ? "inline" : "none";
    }).attr("transform", transform).attr("width", image_size).attr("height", image_size).attr("data-controller", "modal").attr("data-modal-id-value", "#defaultModal").attr("data-modal-url-value", function (d) {
      return documentUrlRoot + '/' + d.id;
    }).on("click", function (d) {
      d3.event.stopPropagation();
    });
    body.append("g").selectAll("text").data(nodes).enter().append("text").attr("class", "label").attr("transform", transform).style("display", function (d) {
      return d.parent === root ? "inline" : "none";
    }).text(function (d) {
      return d.name.trimToLength(25);
    });
    d3.select(window).on("click", function () {
      zoom(root);
    });
    function zoom(d, i) {
      var focus0 = focus;
      focus = d;
      var k = innerDiameter / d.r / 2;
      x.domain([d.x - d.r, d.x + d.r]);
      y.domain([d.y - d.r, d.y + d.r]);
      d3.event.stopPropagation();
      var resizables = d3.selectAll("text,circle,image").transition().duration(d3.event.altKey ? 7500 : 750);
      resizables.filter("circle").attr("cx", function (d) {
        return x(d.x);
      }).attr("cy", function (d) {
        return y(d.y);
      }).attr("r", function (d) {
        return k * d.r;
      });
      resizables.filter("text").attr("transform", function (d) {
        var offset = 0; //d.children ? 0 : image_size / 2;
        return "translate(" + x(d.x) + "," + (y(d.y) + offset) + ")";
      }).filter(function (d) {
        return d.parent === focus || d.parent === focus0;
      }).styleTween("fill-opacity", function (d) {
        return d.parent === focus ? d3.interpolate(0, 1) : d3.interpolate(1, 0);
      }).each("start", function (d) {
        if (d.parent === focus) this.style.display = "inline";
      }).each("end", function (d) {
        if (d.parent !== focus) this.style.display = "none";
      });
      resizables.filter("image").attr("transform", function (d) {
        return "translate(" + (x(d.x) - image_size / 2) + "," + (y(d.y) - image_size / 2) + ")";
      }).filter(function (d) {
        return d.parent === focus || d.parent === focus0;
      }).style('background', '#fff').styleTween("opacity", function (d) {
        return d.parent === focus ? d3.interpolate(0, 1) : d3.interpolate(1, 0);
      })
      // only load image if focus set
      .attr("xlink:href", function (d) {
        return d.children ? empty_image : d.src;
      }).each("start", function (d) {
        if (d.parent === focus) this.style.display = "inline";
      }).each("end", function (d) {
        if (d.parent !== focus) this.style.display = "none";
      });
      //.attr("width",              function(d) { return k * d.r * 2; })
      //.attr("height",             function(d) { return k * d.r * 2; })

      var current = d;
      var context = current.name == 'flare' ? '' : '<li>' + current.name.trimToLength(25) + '</li>';
      while (current = current.parent) {
        // eslint-disable-line no-cond-assign
        if (current.name != 'flare') {
          context = '<li>' + current.name.trimToLength(25) + '</li>' + context;
        }
      }
      $('#tag_circles .breadcrumbs').html(context);
    }
  });
  d3.select(self.frameElement).style("height", outerDiameter + "px");
};
String.prototype.trimToLength = function (length) {
  return this.length > length ? this.substring(0, length - 3) + '...' : this;
};